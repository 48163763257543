<template>
	<div class="index">
		<!-- <Carousel v-if='ban.length' class='indexban' autoplay v-model="value2" loop>
      <CarouselItem v-for="(li,index) in ban" :key='index'>
        <div class="demo-carousel">
          <a v-if="li.jump=='url'" :href="li.url"><img :src="li.thumb" alt=""></a>
          <router-link v-else :to="'/details/cont/'+li.id">
            <img :src="li.thumb" alt="">
          </router-link>
        </div>
      </CarouselItem>
    </Carousel> -->
		<div class="sytext">
			<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
			<img src="../../assets/img/xintp/index-banner.jpg" alt="" style="width:100%;height:auto">
			<p>
				<!-- <span class="index_mv">SUSTech</span>
				<span class="index_mv">Archives</span> -->
				<span class="index_mv" style="font-size:28px">南方科技大学档案校史馆</span>
				<span class="index_mv" style="font-size:28px">（文博中心）</span>
			</p>

		</div>

		<!-- <div class="svg">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"> <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path> <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path> <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path> </svg>
		</div> -->
		<div class="indexbg1">
			<section class="index_mv indexf1" style="padding-bottom:6px">
				<div class="indexImg" style="min-height: 200px;">
					<div v-if="mztj0">
						<Carousel loop arrow="never" autoplay :autoplay-speed="5000"
							:scale="windowWidth < 990 ? 1 : (457 / 895)" w="100%" v-if="mztj0.length > 0">
							<CarouselItem v-for="(item1, index) in mztj0" :key="index">
								<a v-if="item1.url" :href="item1.url" :title="item1.title" target="_blank">
									<div class="demo-carousel">
										<ScaleDiv :scale="455 / 895" :img="item1.thumb"></ScaleDiv>
										<p>{{ item1.title }}</p>
									</div>
								</a>
								<router-link v-else :to="'/details/cont/' + item1.id">
									<div class="demo-carousel">
										<ScaleDiv :scale="455 / 895" :img="item1.thumb"></ScaleDiv>
										<p>{{ item1.title }}</p>
									</div>
								</router-link>
							</CarouselItem>
						</Carousel>
					</div>
					<div v-else>
						载入中...
					</div>
				</div>
				<Carousel :radius-dot='true' loop arrow="never" autoplay class='indexImg1' v-if="mztj0.length > 0">
					<CarouselItem v-for="item1 in mztj0">
						<a v-if="item1.url" :href="item1.url" :title="item1.title" target="_blank">
							<div class="demo-carousel">
								<ScaleDiv :scale="320 / 625" :img="item1.thumb"></ScaleDiv>
								<p>{{ item1.title }}</p>
							</div>
						</a>
						<router-link v-else :to="'/details/text/' + item1.id">
							<div class="demo-carousel">
								<ScaleDiv :scale="320 / 625" :img="item1.thumb"></ScaleDiv>
								<p>{{ item1.title }}</p>
							</div>
						</router-link>
					</CarouselItem>
				</Carousel>
				<div class="left hidden">
					<div class="search">
						<!-- <Input @keydown="keydown" v-model="searchkeyword" placeholder="SEARCH" /> -->
						<input type="text" @keydown="keydown" v-model="searchkeyword" placeholder="SEARCH">
						<img @click="onSearch" src="../../assets/img/xintp/ser.png" alt="">
					</div>
					<div class="imgs">
						<a href="http://www.sustech.edu.cn/zh/academic-calendar.html" target="_blank">
							<img :src="imageTP" alt=""></a>
					</div>
					<div class="serve">
						<div class="serve-item">
							服务大厅
						</div>
						<div class="serve-list">
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/details/text/119?index=1&pid=7&sx=0&catid=8&type=page&show_tile=1&page=1">
										<img src="../../assets/img/xintp/cd.png" alt=""></a>
								</div>
								<div class="list-title">
									<a
										href="https://musahc.sustech.edu.cn/details/text/119?index=1&pid=7&sx=0&catid=8&type=page&show_tile=1&page=1">查档利用</a>
								</div>
							</div>
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/bggk/listGk/120?index=1&pid=7&sx=1&catid=121&type=newscategory&show_tile=1&page=1">
										<img src="../../assets/img/xintp/zt.png" alt=""></a>
								</div>
								<div class="list-title"><a
										href="https://musahc.sustech.edu.cn/bggk/listGk/120?index=1&pid=7&sx=1&catid=121&type=newscategory&show_tile=1&page=1">展厅预约</a>
								</div>
							</div>
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/details/text/128?index=1&pid=7&sx=2&catid=184&type=page&page=1&show_tile=1">
										<img src="../../assets/img/xintp/zj.png" alt=""></a>
								</div>
								<div class="list-title">
									<a
										href="https://musahc.sustech.edu.cn/details/text/128?index=1&pid=7&sx=2&catid=184&type=page&page=1&show_tile=1">我要捐赠</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
				<!-- <section class="index_mv_notice index_mv_notice_ul" style="height:180px;">
        <h3 class="index_mv_notice_ttl" style="font-weight:bold">通知公告</h3>
        <ul class="" style="min-height: 100px;">
          <li class="index_mv_notice_ul_li" v-for="(li,index) in tzgg" :key='index'>
            <a v-if="li.jump=='url'" class="index_mv_notice_ul_li_a" :href="li.url">
              <div>
                {{li.title}}
                <p>{{li.timeline}}</p>
              </div>
            </a>
            <router-link v-else :to="'/details/cont/'+li.id+'?typa=bggk'" class="index_mv_notice_ul_li_a">
              <div>
                {{li.title}}
                <p>{{li.timeline}}</p>
              </div>
            </router-link>
          </li>

          <p class="index_mv_news_list_link show_pctb">
            <router-link to="bggk/listGk/9">查看更多</router-link>
          </p>
        </ul>
      </section>

      <section class="index_mv_news" :style="'height:'+(windowWidth*0.83077/2.000*1.2-160-40-(this.zzbbPadding))+'px;'">
        <h3 class="index_mv_notice_ttl" style="font-weight:bold">重要播报</h3>
        <div class="index_mv_news_ul_wrap" style="padding-top: 0;">
          <ul class="index_mv_news_ul">
            <li ref="zzbbfirst" :style="'margin-bottom:'+(index<2?Math.max(zzbbPadding,0):0)+'px'"
              class="index_mv_news_ul_li" v-for="(li,index) in zybb" :key='index'>
              <a v-if="li.jump=='url'" id="news_link_0"
                class="index_mv_news_ul_li_a index_mv_news_ul_li_a_cell_0 js-news_link" :href="li.url">
                <div class="index_mv_news_ul_li_a_imgwrap index_mv_news_ul_li_a_imgwrap_0">
                  <ScaleDiv :scale="100/168" w="100%" :img="li.thumb">
                  </ScaleDiv>
                </div>
                <time class="index_mv_news_ul_li_a_time">{{li.timeline}}</time>
                <p class="index_mv_news_ul_li_a_p index_mv_news_ul_li_a_p_0">{{li.title}}</p>
              </a>
              <router-link v-else id="news_link_0" :to="'/details/cont/'+li.id"
                class="index_mv_news_ul_li_a index_mv_news_ul_li_a_cell_0 js-news_link">
                <div class="index_mv_news_ul_li_a_imgwrap index_mv_news_ul_li_a_imgwrap_0">
                  <ScaleDiv :scale="100/168" w="100%" :img="li.thumb">
                  </ScaleDiv>
                </div>
                <time class="index_mv_news_ul_li_a_time">{{li.timeline}}</time>
                <p class="index_mv_news_ul_li_a_p index_mv_news_ul_li_a_p_0">{{li.title}}</p>
              </router-link>
            </li>

          </ul>
          <p class="index_mv_news_list_link show_pctb">
            <router-link to="notice/48">查看更多</router-link>
          </p>
        </div>
        <p class="index_mv_news_list_link show_sp">
          <router-link to="notice/48">查看更多</router-link>
        </p>
      </section> -->
			</section>
			<section class="index_mv indexf2 hidden">
				<!-- <div class="left">
					<div class="t">
						通知公告
					</div>
					<div class="index_f2_notice">
						<ul>
							<swiper ref="mySwiper" :options="swiperOptions"  class="swiper-container1">
								<swiper-slide v-for="(li,index) in tzgg" :key='index'  class="swiper-slide1">
									<li> 
										<a v-if="li.jump=='url'" :href="li.url">

											<time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time>
											<p>{{li.title}}</p>
										</a>
										<router-link v-else :to="'/details/cont/'+li.id">

											<time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time>
											<p style="display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;">{{li.title}}</p>
										</router-link>
									</li>
								</swiper-slide>

							</swiper>
						</ul>

					</div>
				</div> -->


				<div class="cen">
					<div class="t" style="display:flex;justify-content: space-between;">
						<div class="t-left" style="font-size:17px">
							<span @click='xwxz = 1' :class="{ 'on': xwxz == 1 }">新闻资讯</span>
							<!-- <span @click='xwxz=2' :class="{'on':xwxz==2}">工作动态</span> -->
						</div>

						<div class="more" v-if="xwxz == 1">
							<router-link :to="'/bggk/listGk/102?catid=102&type=news'">更多</router-link>
						</div>
						<div class="more" v-else>
							<router-link :to="'/bggk/listGk/103?catid=103&type=news'">更多</router-link>
						</div>
					</div>

					<ul v-show="xwxz == 1">
						<swiper ref="mySwiper" :options="swiperOptions1">
							<swiper-slide v-for="(li, index) in xwzx" :key='index'>
								<li class="swiper-slide">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<p><em>• </em>{{ li.title }}</p>
										<time style="font-family:monospace;">{{ li.timeline.substring(0, 10) }}</time>
									</a>
									<router-link v-else :to="'/details/cont/' + li.id">
										<p><em>• </em>{{ li.title }}</p>
										<time style="font-family:monospace;">{{ li.timeline.substring(0, 10) }}</time>
									</router-link>
								</li>
							</swiper-slide>
						</swiper>
					</ul>
					<ul v-show="xwxz == 2">
						<swiper ref="mySwiper" :options="swiperOptions1">
							<swiper-slide v-for="(li, index) in zybb" :key='index'>

								<li class="swiper-slide">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<p><em>• </em>{{ li.title }}</p>
										<time style="font-family:monospace;">{{ li.timeline.substring(0, 10) }}</time>
									</a>
									<router-link v-else :to="'/details/cont/' + li.id">
										<p><em>• </em>{{ li.title }}</p>
										<time style="font-family:monospace;">{{ li.timeline.substring(0, 10) }}</time>
									</router-link>
								</li>

							</swiper-slide>
						</swiper>
					</ul>
				</div>
				<div style="flex: 1;"></div>

				<!-- <div class="right">
					<ul>
						<li>
							<a href="/details/text/8?index=6&pid=7&page=1&catid=30">
								<img src="../../assets/img/xintp/icon1.png" alt="">
								在线办理
							</a>
						</li>
						<li>
							<a href="/fwdt/yyjyF/9?index=6&pid=7&sx=1">
								<img src="../../assets/img/xintp/icon2.png" alt="">
								预约查档
							</a>
						</li> -->
				<!-- <li>
							<a href="">
								<img src="../../assets/img/xintp/icon3.png" alt="">
								预约规则
							</a>
						</li> -->
				<!-- <li>
							<a href="/details/text/68?index=6&pid=7&sx=2&page=1&catid=32">
								<img src="../../assets/img/xintp/icon5.png" alt="">
								档案办理
							</a>
						</li>
						<li>
							<a href="/details/text/101?index=6&pid=7&sx=3&page=1&catid=32">
								<img src="../../assets/img/xintp/icon6.png" alt="">
								自助查询
							</a>
						</li> -->
				<!-- <li>
							<a href="">
								<img src="../../assets/img/xintp/icon4.png" alt="">
								注意事项
							</a>
						</li> -->
				<!-- </ul>
					<div class="btns">
						<span class="zc">注册</span>
						<span>登陆</span>
					</div>
				</div> -->

				<div class="left">
					<div class="t">

						<div class="notice" style="color:#fff;font-size:20px">通知公告</div>
						<div class="more">
							<router-link :to="'/bggk/listGk/66?catid=66&type=news'"
								style="color:#fff;font-size:17px">更多</router-link>
						</div>
					</div>
					<div class="index_f2_notice">
						<ul>
							<swiper ref="mySwiper" :options="swiperOptions" class="swiper-container1">
								<swiper-slide v-for="(li, index) in tzgg" :key='index' class="swiper-slide1">
									<li>
										<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
											<time class="time-title">{{ li.timeline.substring(0, 4) }}年<span
													class="title">{{ li.timeline.substring(5, 7) }}月{{
														li.timeline.substring(8, 10) }}日</span></time>
											<p>{{ li.title }}</p>
										</a>
										<router-link v-else :to="'/details/cont/' + li.id">
											<time class="time-title">{{ li.timeline.substring(0, 4) }}年<span
													class="title">{{ li.timeline.substring(5, 7) }}月{{
														li.timeline.substring(8, 10) }}日</span></time>
											<!-- <time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time> -->
											<p
												style="display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;">
												{{ li.title }}</p>
										</router-link>
									</li>
								</swiper-slide>

							</swiper>
						</ul>

					</div>
				</div>
			</section>
			<div class="sjys flor1">

				<!-- <ul class="anlist">
					<li>
						<a href="/details/text/8?index=6&pid=7&page=1&catid=30">
							<img src="../../assets/img/xintp/icon1.png" alt="">
							在线办理
						</a>
					</li>
					<li>
						<a href="/fwdt/yyjyF/9?index=6&pid=7&sx=1">
							<img src="../../assets/img/xintp/icon2.png" alt="">
							预约查档
						</a>
					</li> -->
				<!-- <li>
						<a href="">
							<img src="../../assets/img/xintp/icon3.png" alt="">
							预约规则
						</a>
					</li> -->
				<!-- <li>
						<a href="/details/text/68?index=6&pid=7&sx=2&page=1&catid=32">
							<img src="../../assets/img/xintp/icon5.png" alt="">
							档案办理
						</a>
					</li>
					<li>
						<a href="/details/text/101?index=6&pid=7&sx=3&page=1&catid=32">
							<img src="../../assets/img/xintp/icon6.png" alt="">
							自助查询
						</a>
					</li> -->
				<!-- <li>
						<a href="">
							<img src="../../assets/img/xintp/icon4.png" alt="">
							注意事项
						</a>
					</li> -->
				<!-- </ul> -->
				<div class="ip-serve">
					<div class="serve">
						<div class="serve-item" style="padding-bottom:10px">
							服务大厅
						</div>
						<div class="serve-list">
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/details/text/119?index=1&pid=7&sx=0&catid=8&type=page&show_tile=1&page=1">
										<img src="../../assets/img/xintp/cd.png" alt=""></a>
								</div>
								<div class="list-title">
									<a
										href="https://musahc.sustech.edu.cn/details/text/119?index=1&pid=7&sx=0&catid=8&type=page&show_tile=1&page=1">查档利用</a>
								</div>
							</div>
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/bggk/listGk/120?index=1&pid=7&sx=1&catid=121&type=page">
										<img src="../../assets/img/xintp/zt.png" alt=""></a>
								</div>
								<div class="list-title"><a
										href="https://musahc.sustech.edu.cn/bggk/listGk/120?index=1&pid=7&sx=1&catid=121&type=newscategory&show_tile=1&page=1">展厅预约</a>
								</div>
							</div>
							<div class="list">
								<div class="img">
									<a
										href="https://musahc.sustech.edu.cn/details/text/127?index=1&pid=7&sx=2&catid=184&type=page&show_tile=1&page=1">
										<img src="../../assets/img/xintp/zj.png" alt=""></a>
								</div>
								<div class="list-title">
									<a
										href="https://musahc.sustech.edu.cn/details/text/127?index=1&pid=7&sx=2&catid=184&type=page&show_tile=1&page=1">我要捐赠</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="left">
					<div class="t" style=" display: flex;align-items: center;justify-content: space-between;">
						<div class="notice" style="color:#fff;font-size:20px;">通知公告</div>
						<div class="more-mobile">
							<router-link :to="'/bggk/listGk/66?catid=66&type=news'"
								style="color:#fff;font-size:17px">更多</router-link>
						</div>
					</div>
					<div class="index_f2_notice">
						<ul>
							<swiper ref="mySwiper" :options="swiperOptions" class="swiper-container1">
								<swiper-slide v-for="(li, index) in tzgg" :key='index' class="swiper-slide1">
									<li>
										<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
											<time class="time-title">{{ li.timeline.substring(0, 4) }}年<span
													class="title">{{ li.timeline.substring(5, 7) }}月{{
														li.timeline.substring(8, 10) }}日</span></time>
											<p>{{ li.title }}</p>
										</a>
										<router-link v-else :to="'/details/cont/' + li.id">
											<time class="time-title">{{ li.timeline.substring(0, 4) }}年<span
													class="title">{{ li.timeline.substring(5, 7) }}月{{
														li.timeline.substring(8, 10) }}日</span></time>
											<!-- <time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time> -->
											<p
												style="display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;">
												{{ li.title }}</p>
										</router-link>
									</li>
								</swiper-slide>

							</swiper>
						</ul>

						<!-- <div class="swiper-container">
							<ul class="swiper-wrapper">
								<li class="swiper-slide" v-for="(li,index) in tzgg" :key='index'>
									<a v-if="li.jump=='url'" :href="li.url">
										<time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time>
										<p>{{li.title}}</p>
									</a>
									<router-link v-else :to="'/details/cont/'+li.id">
										<time>{{li.timeline.substring(0,4)}}.{{li.timeline.substring(5,7)}}<span>{{li.timeline.substring(8,10)}}</span></time>
										<p>{{li.title}}</p>
									</router-link>
								</li>
							</ul>
						</div> -->
					</div>
				</div>

			</div>
		</div>
		<div class="sjbj">

			<div class="sjys index_feature_ul flor2" style="padding: 0; margin: 0 auto 0;">
				<a href="http://www.sustech.edu.cn/zh/academic-calendar.html" target="_blank">
					<img style="width: 100%;" :src="imageTP" alt="">
				</a>
				<div class="cen">
					<div class="t">
						<span @click='xwxz = 1' :class="{ 'on': xwxz == 1 }">新闻资讯</span>
						<!-- <span @click='xwxz=2' :class="{'on':xwxz==2}">工作动态</span> -->
					</div>
					<ul v-show="xwxz == 1" style="padding-bottom:10px">
						<swiper ref="mySwiper" :options="swiperOptions1">
							<swiper-slide v-for="(li, index) in xwzx" :key='index'>
								<li class="swiper-slide">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<p><em>• </em>{{ li.title }}</p>
										<time>{{ li.timeline.substring(0, 10) }}</time>
									</a>
									<router-link v-else :to="'/details/text/' + li.id + 'index=3&pid=16&sx=0&page=1'">
										<p><em>• </em>{{ li.title }}</p>
										<time>{{ li.timeline.substring(0, 10) }}</time>
									</router-link>
								</li>
							</swiper-slide>
						</swiper>
					</ul>
					<!-- </div> -->
					<ul v-show="xwxz == 2" style="padding-bottom:10px">
						<swiper ref="mySwiper" :options="swiperOptions1">
							<swiper-slide v-for="(li, index) in zybb" :key='index'>

								<li class="swiper-slide">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<p><em>• </em>{{ li.title }}</p>
										<time>{{ li.timeline.substring(0, 10) }}</time>
									</a>
									<router-link v-else :to="'/details/cont/' + li.id">
										<p><em>• </em>{{ li.title }}</p>
										<time>{{ li.timeline.substring(0, 10) }}</time>
									</router-link>
								</li>

							</swiper-slide>
						</swiper>
					</ul>
					<div class="more_m" v-if="xwxz == 1">
						<router-link :to="'/bggk/listGk/102?catid=102&type=news'">更多></router-link>
					</div>
					<div class="more_m" v-else>
						<router-link :to="'/bggk/listGk/103?catid=103&type=news'">更多></router-link>
					</div>

				</div>


			</div>
		</div>
		<!-- <section class="index_feature index_nkjp">
			<h2 class="index_feature_ttl index_feature_ul js-parallax typesquare_option scrolled index-title-b"
				style="margin: 0 auto;">南科精品<div class="swiper-button-next swiper-button-next2" slot="button-next"></div>
				<div class="swiper-button-prev swiper-button-prev2" slot="button-prev"></div>
			</h2>
			<ul class="index_feature_ul js-parallax">
				<swiper ref="mySwiper2" :options="swiperOptions2">

					<swiper-slide v-for="(li,index) in nkjp" :key='index'>

						<li style="width: 100%;" class="index_feature_ul_li">
							<a v-if="li.jump=='url'" class="index_feature_ul_li_a" :href="li.url">
								<div class="index_feature_ul_li_a_imgwrap">
									<ScaleDiv :scale="175/260" w="100%" :img="li.thumb">
									</ScaleDiv>
								</div>
								<p class="index_feature_ul_li_a_p">
									{{li.title}}
								</p><span class="time">{{li.timeline}}</span>
							</a>
							<router-link :to="'/details/cont/'+li.id" v-else class="index_feature_ul_li_a">
								<div class="index_feature_ul_li_a_imgwrap">
									<ScaleDiv :scale="175/260" w="100%" :img="li.thumb">
									</ScaleDiv>
								</div>
								<p class="index_feature_ul_li_a_p">
									{{li.title}}
								</p><span class="time">{{li.timeline}}</span>
							</router-link>
						</li>
					</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div> -->

		<!-- </swiper> -->

		<!-- </ul> -->
		<!-- <div class="more sjys">
				<a href="">查看更多</a>
			</div> -->
		<!-- </section> -->

		<div class="indexbg1">
			<section class="index_content index_mztj">
				<h2 class="index_content_ttl index_content_ul js-parallax  typesquare_option scrolled index-title-b">
					<router-link :to="'/bggk/listGk/38?catid=38&type=news'">精品推荐</router-link>
					<div class="swiper-button-next swiper-button-next1" slot="button-next"></div>
					<div class="swiper-button-prev swiper-button-prev1" slot="button-prev"></div>
				</h2>
				<ul class="index_content_ul js-parallax scrolled">
					<swiper ref="mySwiper2" :options="swiperOptions3">
						<swiper-slide v-for="(li, index) in mztj" :key='index'>


							<li class="index_content_ul_li" style="width: 100%;">
								<a class="index_content_ul_li_a" v-if="li.url" :href="li.url" :title="li.title"
									target="_blank">
									<div class="index_content_ul_li_a_img">
										<ScaleDiv :scale="260 / 407" w="100%" :img="li.thumb">
										</ScaleDiv>
									</div>
									<h2 class="index_content_ul_li_a_ttl">{{ li.title }}</h2>
									<p class="index_content_ul_li_a_p">{{ li.description }}</p>
									<!-- <p class="index_content_ul_li_a_button">查看详情</p> -->
								</a>
								<router-link :to="'/details/cont/' + li.id" class="index_content_ul_li_a" v-else>
									<div class="index_content_ul_li_a_img">
										<ScaleDiv :scale="260 / 407" w="100%" :img="li.thumb">
										</ScaleDiv>
									</div>
									<h2 class="index_content_ul_li_a_ttl">{{ li.title }}</h2>
									<p class="index_content_ul_li_a_p">{{ li.description }}</p>
									<!-- <p class="index_content_ul_li_a_button">查看详情</p> -->
								</router-link>
							</li>
						</swiper-slide>
					</swiper>
					<div class="jingpin-more">
						<router-link :to="'/bggk/listGk/38?catid=38&type=news'"> 更多></router-link>
					</div>
				</ul>
				<!-- <div style="margin: 0 0 40px;" class="more sjys">
					<a style="color: #eee; border-color: #eee;" href="">查看更多</a>
				</div> -->
				<!-- <img class="sjys" style=" width: 100%; padding: 0 0 40px;" src="../../assets/img/xintp/tp1.jpg" alt=""> -->
				<!-- <h2 class="index_content_ttl js-parallax sjys typesquare_option scrolled index-title-b">常用链接</h2> -->
			</section>
			<!-- 兰台文化 -->
			<section class="index_content index_mztj">
				<h2 class="index_content_ttl index_content_ul js-parallax  typesquare_option scrolled index-title-b">
					<router-link :to="'/bggk/listGk/40?index=2&pid=128&sx=5&catid=40&type=news'">兰台文化</router-link>
					<div class="swiper-button-next swiper-button-next4" slot="button-next"></div>
					<div class="swiper-button-prev swiper-button-prev4" slot="button-prev"></div>
				</h2>
				<ul class="index_content_ul js-parallax scrolled">
					<swiper ref="mySwiper2" :options="swiperOptions4">
						<swiper-slide v-for="(li, index) in ltwh" :key='index'>
							<li class="index_content_ul_li" style="width: 100%;">
								<a class="index_content_ul_li_a" v-if="li.url" :href="li.url" :title="li.title"
									target="_blank">
									<div class="index_content_ul_li_a_img">
										<ScaleDiv :scale="260 / 407" w="100%" :img="li.thumb">
										</ScaleDiv>
									</div>
									<h2 class="index_content_ul_li_a_ttl">{{ li.title }}</h2>
									<p class="index_content_ul_li_a_p">{{ li.description }}</p>
								</a>
								<router-link :to="'/details/cont/' + li.id" class="index_content_ul_li_a" v-else>
									<div class="index_content_ul_li_a_img">
										<ScaleDiv :scale="260 / 407" w="100%" :img="li.thumb">
										</ScaleDiv>
									</div>
									<h2 class="index_content_ul_li_a_ttl">{{ li.title }}</h2>
									<p class="index_content_ul_li_a_p">{{ li.description }}</p>
								</router-link>
							</li>
						</swiper-slide>
					</swiper>
					<div class="jingpin-more">
						<router-link :to="'/bggk/listGk/40?index=2&pid=128&sx=5&catid=40&type=news'"> 更多></router-link>
					</div>
				</ul>

			</section>
		</div>


		<!-- <div class="index_feature " style="padding-bottom:60px">
			<a v-for="(li,index) in listN" :key='index' class="index_banner md-banner1 js-parallax scrolled" :href="li.url">
				<div class=" md-banner1_w" :style="'background-size:cover;background-position: 50% 70%;background-image: url('+li.thumb+');'">
					<p class="index_banner_wrap_ttl md-banner1_w_ttl"><span class="index_banner_wrap_ttl_span md-banner1_w_ttl_span">{{li.name}}</span></p>
				</div>
			</a>
		</div> -->

		<!-- <div class="index_notice">
			<h2 class="index_feature_ttl js-parallax typesquare_option scrolled index-title-b" style="margin-bottom:50px;">
				兰台文化</h2>
			<ul class="index_notice_ul js-parallax scrolled">
				<li class="index_notice_ul_li" v-for="(li,index) in notice" :key='index'>
					<a class="index_notice_ul_li_a" style="text-align:left" v-if="li.jump=='url'" :href="li.url">
						<div class="index_notice_ul_li_a_img">
							<ScaleDiv :scale="175/260" w="100%" :img="li.thumb">
							</ScaleDiv>
						</div>
						<h3 class="index_notice_ul_li_a_ttl"><span>{{li.title}}</span></h3>
						<p class="index_notice_ul_li_a_p">{{li.description}}</p>
					</a>
					<router-link :to="'/details/cont/'+li.id" class="index_content_ul_li_a" style="text-align:left" v-else>
						<div class="index_notice_ul_li_a_img">
							<ScaleDiv :scale="175/260" w="100%" :img="li.thumb">
							</ScaleDiv>
						</div>
						<h3 class="index_notice_ul_li_a_ttl"><span>{{li.title}}</span></h3>
						<p class="index_notice_ul_li_a_p">{{li.description}}</p>
					</router-link>
				</li>

			</ul>
		</div> -->
		<!-- <div class="index_banners" v-if="listS.length">
			<ul class="index_banners_ul js-parallax">
				<li class="index_banners_ul_li" v-for="(li,index) in listS" :key='index'>
					<a v-if="li.jump=='url'" class="index_banners_ul_li_a md-banner1 feel" :href="li.url" target="_blank">
						<div class="md-banner1_w">
							<ScaleDiv :scale="80/391" w="100%" :img="li.thumb">
							</ScaleDiv>
						</div>
					</a>
					<router-link :to="'/details/cont/'+li.id" v-else class="index_banners_ul_li_a md-banner1 feel">
						<div class="md-banner1_w">
							<ScaleDiv :scale="80/391" w="100%" :img="li.thumb">
							</ScaleDiv>
						</div>
					</router-link>
					<p class="index_banners_ul_li_p">{{li.name}}</p>
				</li>
			</ul>
		</div> -->
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import ScaleDiv from '@/components/ScaleDiv.vue';

export default {
	components: {
		ScaleDiv,
	},
	data() {
		return {
			imageTP: this.$api.getUrl('/files/images/tp.jpg'),
			windowHeight: 600,
			windowWidth: 600,
			zzbbPadding: 20,
			value2: 0,
			mztj0: [], // 首页详情
			ban: [],
			list: [],
			listN: [],
			listS: [],
			zybb: [], //新闻动态
			tzgg: [], //通知公告
			mztj: [],
			ltwh:[],
			nkjp: [],
			notice: [],
			searchkeyword: '',
			link: [],
			link1: [],
			link2: [],
			link3: [],
			xwzx: [],
			xwxz: 1,

			swiperOptions: {
				loop: true,
				direction: 'vertical',
				slidesPerView: 2,
				height: 90,
				autoplay: {
					delay: 3000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
			},
			swiperOptions1: {
				loop: false,
				height: 33,
				direction: 'vertical',
				slidesPerView: 8,
			},
			swiperOptions2: {
				breakpoints: {
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 4,
						spaceBetween: 30,
					},
				},
				navigation: {
					nextEl: '.swiper-button-next2',
					prevEl: '.swiper-button-prev2',
				},
				slidesPerView: 4,
				spaceBetween: 30,
				autoplay: {
					delay: 7000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
				loop: true,
			},
			swiperOptions3: {
				loop: true,
				breakpoints: {
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
				},
				navigation: {
					nextEl: '.swiper-button-next1',
					prevEl: '.swiper-button-prev1',
				},
				slidesPerView: 3,
				spaceBetween: 30,
				autoplay: {
					delay: 5000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
			},
						swiperOptions4: {
				loop: true,
				breakpoints: {
					320: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
				},
				navigation: {
					nextEl: '.swiper-button-next4',
					prevEl: '.swiper-button-prev4',
				},
				slidesPerView: 3,
				spaceBetween: 30,
				autoplay: {
					delay: 5000,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
			},
		};
	},

	name: 'Slider',
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
			this.onResize();
		});
	},

	methods: {
		keydown(event) {
			var that = this;
			console.log(event);
			if (event.which == 13) {
				that.onSearch();
			}
		},
		onSearch() {
			var that = this;
			if (!that.searchkeyword) {
				that.$Message.error('请输入搜索词！');
				return;
			}
			that.$router.push({
				name: 'search',
				params: {
					searchtext: that.searchkeyword,
				},
			});
		},
		onResize() {
			this.windowHeight = window.innerHeight;
			this.windowWidth = Math.min(window.innerWidth, 1332 / 0.83077);

			if (!this.$refs.zzbbfirst) {
				setTimeout(() => {
					this.onResize();
				}, 50);
				return;
			}
			var h = this.$refs.zzbbfirst[0].offsetHeight;
			this.zzbbPadding = Math.max(
				0,
				((this.windowWidth * 0.83077) / 2.0) * 1.2 - 160 - 40 - (45 + this.zzbbPadding * 0.5) - h * 2
			);
		},
	},
	created() {
		var that = this;

		that.$api
			.home()
			.then((res) => {
				that.$emit('getLoad', true);
				that.ban = res.banner1;
				that.listN = res.banner2.slice(1, 2);
				that.listS = res.banner3;
				that.nkjp = res.catid46; // 南科精品
				// console.log(that.nkjp)
				that.mztj0 = res.catid47.slice(0, 6); //首页
				that.mztj = res.catid47.slice(6, res.catid47.length); // 每周推荐
				that.ltwh = res.catid40.slice(0,6); // 兰台
				console.log(that.mztj);
				that.zybb = res.catid48; //工作动态
				that.notice = res.fid49;
				that.tzgg = res.fid9; // 通知公告
				that.xwzx = res.catid106; //新闻资讯
				setTimeout(() => {
					this.onResize();
				}, 100);
			})
			.catch((error) => {
				// error
				console.log(error);
			});
	},
};
</script>

<style>
.more_m {
	padding: 0 10px 20px;
	text-align: right;
}

.more_m a {
	/* color: #8b8989; */
}

.jingpin-more {
	padding-top: 8px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	font-size: 18px;
}

.jingpin-more a {
	color: #69d1d1;
	font-weight: bold;
}

@media only screen and (max-width: 800px) {
	.jingpin-more a {
		color: #fff;
		font-weight: bold;
	}
}
</style>
